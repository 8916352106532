import React, { ReactNode } from "react"
import Tooltip from "@common/tooltip/tooltip"

interface TooltipWrapperProps {
  trigger?: ReactNode
  content?: ReactNode
  position?: string
  children: ReactNode
  disabled?: boolean
  strategy?: "fixed" | "absolute"
  triggerClass?: string
}

const TooltipWrapper = ({
  trigger,
  content,
  children,
  position,
  disabled = false, //show tooltip
  strategy,
  triggerClass,
}: TooltipWrapperProps) => {
  if (disabled || (!trigger && !content)) {
    return <>{children}</>
  }

  if (trigger)
    return (
      <Tooltip position={position} strategy={strategy} triggerClass={triggerClass}>
        <Tooltip.Trigger>{trigger}</Tooltip.Trigger>
        <Tooltip.Content>{children}</Tooltip.Content>
      </Tooltip>
    )

  if (content)
    return (
      <Tooltip position={position} triggerClass={triggerClass}>
        <Tooltip.Trigger>{children}</Tooltip.Trigger>
        <Tooltip.Content>{content}</Tooltip.Content>
      </Tooltip>
    )

  return <>{children}</>
}

export default TooltipWrapper
